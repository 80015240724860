import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BannerImage from "../../sub-components/banner-image";

const Latest_News = () => {
  const baseUrl =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_BASE_URL_DEV
      : process.env.REACT_APP_BASE_URL_PROD;

  const [latestNewsInfo, setLatestNewsInfo] = useState([]);

  const getLatestNewsInfo = () => {
    fetch(`${baseUrl}/allDataInfo/latestnewsInfo.php`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);
        setLatestNewsInfo([...data].reverse());

        const sortedData = latestNewsInfo.map(item => {
          // Handle specific cases where the dateString is not a full date (e.g., 'July 2023')
          const dateParts = item.dateString.split(' ');
          let date;
      
          if (dateParts.length === 2) {
              // For 'July 2023', set the day to the first of the month
              date = new Date(`${dateParts[0]} 1, ${dateParts[1]}`);
          } else {
              date = new Date(item.dateString);
          }
      
          return { ...item, date };
      }).sort((a, b) => a.date - b.date);
      
      // Output the sorted data
      console.log(sortedData);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  useEffect(() => {
    getLatestNewsInfo();
  }, []);

  return (
    <>
      <div className="bg-[#ffff]">
        <BannerImage />
        <section className="relative mx-5 mt-5 xl:pl-[calc((100%-1200px)/2)] xl:pr-[calc((100%-1200px)/2)] overflow-hidden">
          <div className="">
            <h4 className="text-[14px] font-thin text-[#333333]">
              <Link to="/">Home</Link> / Latest News
            </h4>
            <h1 className="text-[24px] lg:text-[28px] font-semibold mt-5 text-[#333333] ">
              Latest News
            </h1>
            <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4">
              {latestNewsInfo.map((news, index) => (
                <div className="mb-5 carousel-item pb-5">
                  <div
                    className="w-[275px] md:w-[280px] h-[480px] border-1  bg-[#f5f5f5] shadow-xl rounded-[10px] relative mt-10  min-[375px]:ml-11 min-[425px]:ml-16 md:ml-5 lg:ml-0"
                    key={index}
                  >
                    <a href={news.redirectLink}>
                      <img
                        src={`./assets/${news.imgUrl}`}
                        alt=""
                        className="w-full h-80 object-cover rounded-t-[10px]"
                      />
                      <div className="p-2.5">
                        <p className="text-[12px] lg:text-[14px] font-normal text-[#072FA5]">
                          {news.dateString}
                        </p>
                        <p className="text-[14px] lg:text-[16px] font-normal text-[#333333] !leading-7">
                          {news.content}
                        </p>
                      </div>
                    </a>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Latest_News;
